import * as React from "react";
import styled from "styled-components";
import { defaultTheme } from "../../styles/theme";
import { Align } from "./Align";
import { Text } from "./Text";
import { respondTo } from "../../styles/respondTo";

interface TextInputProps {
  rows: number;
  placeholder: string;
  name: string;
  register: any;
  error: boolean;
  type: "text" | "area";
}

interface TextInputError {
  error?: boolean;
}
const StyledTextArea = styled.textarea<TextInputError>`
  width: 100%;
  box-sizing: border-box;
  resize: none;
  background-color: ${defaultTheme.colors.brandBlack};
  color: ${defaultTheme.colors.white};
  font-family: "Raleway";
  border-radius: ${defaultTheme.borderRadius.medium};
  padding: ${defaultTheme.spacing.xs} ${defaultTheme.spacing.s};
  margin-top: ${defaultTheme.spacing.base};
  font-size: ${defaultTheme.fontSize.l};
  outline: none;
  border: ${({ error }) => error && `2px solid ${defaultTheme.colors.red}`};
  scroll-margin: 25px 0;

  @media (max-width: 601px) {
    font-size: ${defaultTheme.fontSize.m};
  }
  &::placeholder {
    font-size: ${defaultTheme.fontSize.l};
    @media (max-width: 601px) {
      font-size: ${defaultTheme.fontSize.m};
    }
  }

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${defaultTheme.colors.primary};
    border-radius: ${defaultTheme.borderRadius.large};
  }
  ::-webkit-scrollbar-track {
    margin: 25px 0;
    border-radius: ${defaultTheme.borderRadius.large};
  }

  ${respondTo.tablet`
    margin-top: ${defaultTheme.spacing.xl};

`};
`;

const StyledTextInput = styled.input<TextInputError>`
  width: 100%;
  background-color: ${defaultTheme.colors.brandBlack};
  color: ${defaultTheme.colors.white};
  font-family: "Raleway";
  box-sizing: border-box;
  padding: 0 ${defaultTheme.spacing.s};
  margin-bottom: ${defaultTheme.spacing.s};
  font-size: ${defaultTheme.fontSize.l};
  outline: none;
  border: none;
  border-bottom: ${({ error }) => `1px solid ${error ? defaultTheme.colors.red : defaultTheme.colors.lightWhite}`};
  border-color: gray;
  margin-bottom: 4vw;
  margin-top: 4vw;

  @media (max-width: 601x) {
    font-size: ${defaultTheme.fontSize.m};
  }

  &::placeholder {
    font-size: ${defaultTheme.fontSize.l};

    @media (max-width: 601px) {
      font-size: ${defaultTheme.fontSize.m};
    }
  }
`;

export const TextInput = ({ rows, placeholder, name, register, error, type }: TextInputProps) => {
  const getInputForType = () => {
    switch (type) {
      case "text":
        return <StyledTextInput placeholder={placeholder} name={name} ref={register} error={error} />;
      case "area":
        return <StyledTextArea rows={rows} placeholder={placeholder} name={name} ref={register} error={error} />;
      default:
        return <StyledTextInput placeholder={placeholder} name={name} ref={register} error={error} />;
    }
  };

  return (
    <Align direction="column" align="flex-start" justify="center">
      {getInputForType()}
    </Align>
  );
};
