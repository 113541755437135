export const breakpoints = {
  mobileXS: "280px",
  mobile326: "326px",
  mobile360: "360px",
  mobileS: "375px",
  mobile400: "400px",
  mobileM: "430px",
  mobile570: "570px",
  mobile: "600px",
  tabletS: "768px",
  tablet: "1000px",
  tablet900: "900px",
  tabletL: "1030px",
  desktop1200: "1200px",
  desktopS: "1366px",
  desktop: "1400px",
  desktopL: "1600px",
  desktopHD: "1920px",
  desktopMac: "2560px",
  desktopMacP: "3072px",
  desktop4k: "3840px"
};
