import React from "react";

import styled from "styled-components";
import { respondTo } from "../../styles/respondTo";
import { defaultTheme } from "../../styles/theme";
import "../../styles/typography.css";

interface TextProperties {
  color: string;
  align?: "center" | "left" | "right" | "justify";
  bold?: boolean;
  fontWeight?: number;
  noSelect?: boolean;
  spacing?: string;
  fontFamily?: string;
  margin?: string;
}

const StyledText = styled.span<TextProperties>`
  text-align: ${({ align }) => align};
  color: ${({ color }) => (color ? color : "black")};
  padding: ${({ spacing }) => spacing || defaultTheme.spacing.s} 0;
  font-weight: ${({ bold, fontWeight }) => (bold ? "bold" : fontWeight ? fontWeight : "light")};
  user-select: ${({ noSelect }) => noSelect && "none"};
  font-family: Raleway;
  letter-spacing: 2px;
  margin: 0 ${({ margin }) => margin};
`;

const SmallText = styled(StyledText)`
  font-size: ${defaultTheme.fontSize.s};
`;

const MediumText = styled(StyledText)`
  font-size: ${defaultTheme.fontSize.l};

  ${respondTo.desktop`
  font-size:${defaultTheme.fontSize.m}
  `};

  ${respondTo.tablet`
  font-size:${defaultTheme.fontSize.s}
`};

  ${respondTo.mobile`
  font-size:${defaultTheme.fontSize.xs};
  `};
`;

const LargeText = styled(StyledText)`
  font-size: ${defaultTheme.fontSize.xl};

  ${respondTo.desktop`
  font-size:${defaultTheme.fontSize.l}
  `}

  ${respondTo.mobile`
  font-size:${defaultTheme.fontSize.m};
  padding:${defaultTheme.spacing.base} 0;
  `};
`;

const VeryLargeText = styled(StyledText)`
  font-size: ${defaultTheme.fontSize.xxl};

  ${respondTo.desktop`
  font-size: ${defaultTheme.fontSize.xl};
  
  `}
`;

interface TextProps extends TextProperties {
  size: "small" | "medium" | "large" | "huge";
  children?: any;
}

export const Text = (props: TextProps) => {
  switch (props.size) {
    case "small":
      return <SmallText {...props}>{props.children}</SmallText>;

    case "medium":
      return <MediumText {...props}>{props.children}</MediumText>;

    case "large":
      return <LargeText {...props}>{props.children}</LargeText>;

    case "huge":
      return <VeryLargeText {...props}>{props.children}</VeryLargeText>;
  }
};
