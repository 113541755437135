export const colors = {
  brand: "#87FF63",
  white: "#ffffff",
  black: "#1b1b1b",
  lightGrey: "#d3d4da",
  lightGreyBackground: "rgba(241,239,245,0.5)",
  grey: "#828282",
  darkGrey: "#181818",
  mediumGrey: "#4f4f4f",
  primary: "#3F3D56",
  accent: "#6E6B96",
  green: "#8cc63f",
  divider: "rgba(110, 107, 150, 0.1)",
  darkPurple: "#393750",
  headerDark: "#4E4C6B",
  logoGreen: "#8CC63F",
  red: "red",
  brandBlack: "#181818",
  lightWhite: "#EEEEEE",
  geeksGreen: "#82B73C",
  textLight: "#eeeeee"
};
