import styled from "styled-components";
import { defaultTheme } from "../../styles/theme";

interface DividerProps {
  spacing?: string;
}

export const Divider = styled.div<DividerProps>`
  width: 300px;
  height: 0px;
  border: 1px solid ${defaultTheme.colors.divider};
  margin: ${({ spacing }) => spacing && spacing} 0;
`;
