import styled from "styled-components";
import * as React from "react";
import { Align } from "./Align";
import { Title } from "./Title";
import { Text } from "./Text";
import { defaultTheme } from "../../styles/theme";
import arrowDown from "../../assets/images/arrow-down.svg";
import arrowUp from "../../assets/images/arrow-up.svg";
import { Divider } from "./Divider";
import { useText } from "../../common/hooks/useText";
import { useOutsideClick } from "../../common/hooks/useOutsideClick";

interface SelectProps {
  options: string[];
  currentOption: number;
  setCurrentOption: (currentOption: number) => void;
}

const SelectContainer = styled.div`
  padding: ${defaultTheme.spacing.xs};
  background-color: ${defaultTheme.colors.darkPurple};
  border-radius: ${defaultTheme.borderRadius.medium};
  position: relative;
  cursor: pointer;
`;

const SelectOptionsContainer = styled.div`
  padding: ${defaultTheme.spacing.xs};
  background-color: ${defaultTheme.colors.darkPurple};
  border-radius: ${defaultTheme.borderRadius.medium};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const SingleOptionContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const MainContainer = styled.div`
  position: relative;
`;

const OptionsHeaderContainer = styled.div`
  cursor: pointer;
`;

export const Select = ({
  currentOption,
  setCurrentOption,
  options,
}: SelectProps) => {
  const { text } = useText();

  const [open, setOpen] = React.useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideClick(wrapperRef, () => setOpen(false));
  const {
    colors: { green, accent, lightGrey },
  } = defaultTheme;
  return (
    <MainContainer ref={wrapperRef}>
      <SelectContainer onClick={() => setOpen(true)}>
        <Align direction="row" align="center" justify="space-between">
          <Align direction="column" align="flex-start" justify="center">
            <Title size="small" color={accent}>
              {text("contact.select")}
            </Title>
            <Title size="medium" color={lightGrey}>
              {options[currentOption]}
            </Title>
          </Align>
          <img src={arrowDown} />
        </Align>
      </SelectContainer>
      {open && (
        <SelectOptionsContainer>
          <OptionsHeaderContainer onClick={() => setOpen(false)}>
            <Align direction="row" align="center" justify="space-between">
              <Title size="small" color={accent}>
                project type
              </Title>
              <img src={arrowUp} />
            </Align>
          </OptionsHeaderContainer>
          <Align direction="column" align="flex-start" justify="center">
            {options.map((option, index) => (
              <div key={index}>
                <SingleOptionContainer
                  onClick={() => {
                    setCurrentOption(index);
                    setOpen(false);
                  }}
                >
                  <Text
                    size="huge"
                    color={index === currentOption ? green : lightGrey}
                  >
                    {option}
                  </Text>
                </SingleOptionContainer>
                {index < options.length - 1 && <Divider />}
              </div>
            ))}
          </Align>
        </SelectOptionsContainer>
      )}
    </MainContainer>
  );
};
