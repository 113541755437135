import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    typeof message === "string" &&
    message.startsWith("[React Intl] Missing message:")
  ) {
    return;
  }
  consoleError(message, ...args);
};

export const useText = () => {
  const intl = useIntl();

  const singleText = (id: string) => {
    return intl.formatMessage({ id, defaultMessage: id });
  };

  const text = (object: any) => {
    if (typeof object === "string") {
      return singleText(object);
    }

    if (Array.isArray(object)) {
      return object.map((element) => text(element));
    }

    if (typeof object === "object") {
      const newObject = {};
      Object.keys(object).forEach((key) => {
        newObject[key] = text(object[key]);
      });
      return newObject;
    }

    return object;
  };

  return { text };
};
