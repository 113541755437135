import styled from "styled-components";

interface AlignProps {
  direction: "row" | "column" | "row-reverse" | "column-reverse";
  align: string;
  justify: string;
  wrap?: boolean;
  gap?: string;
}

export const Align = styled.div<AlignProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  /* width: 100%; */
  height: 100%;
  gap: ${({ gap }) => (gap ? gap : "initial")};
`;
