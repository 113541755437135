import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const image = require("../../../static/geeknauts.png");

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;

function Metadata({
  description,
  title,
}: {
  title: string;
  description?: string;
}) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      defer={false}
      defaultTitle={title}
      titleTemplate={`%s | ${defaultTitle}`}
    >
      <html lang="en" />
      <link rel="canonical" href={seo.url} />

      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <title>{title}</title>
      <meta name="description" content={defaultDescription} />

      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={defaultTitle} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:determiner" content="" />

      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />

      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  );
}

export default Metadata;
