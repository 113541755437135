import React from "react";

import styled from "styled-components";
import "../../styles/typography.css";
import { defaultTheme } from "../../styles/theme";
import { respondTo } from "../../styles/respondTo";

interface TitleProperties {
  color: string;
  align?: "center" | "left" | "right" | "justify";
  bold?: boolean;
}

const StyledTitle = styled.div<TitleProperties>`
  text-align: ${({ align }) => (align ? align : "left")};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-family: Raleway;
`;

const SmallTitle = styled(StyledTitle)`
  font-size: ${defaultTheme.fontSize.l};
`;

const MediumTitle = styled(StyledTitle)`
  margin: 0 ${defaultTheme.spacing.xs};
  font-size: ${defaultTheme.fontSize.xxl};
`;

const LargeTitle = styled(StyledTitle)`
  font-size: ${defaultTheme.fontSize.xxxl};
  letter-spacing: ${defaultTheme.spacing.s};
  text-transform: uppercase;

  ${respondTo.desktopL`
  font-size: ${defaultTheme.fontSize.xxl};
  `};

  ${respondTo.desktopS`
  font-size: ${defaultTheme.fontSize.xl};
  letter-spacing: ${defaultTheme.spacing.xs};
  `};

  ${respondTo.tablet`
  font-size:${defaultTheme.fontSize.xxl};
  letter-spacing: ${defaultTheme.spacing.xxs};
  `};

  ${respondTo.tabletS`

  `};

  ${respondTo.mobile`
  font-size:${defaultTheme.fontSize.xl}
  `};
`;

const VeryLargeTitle = styled(StyledTitle)`
  font-size: ${defaultTheme.fontSize.xxxxl};

  ${respondTo.tablet`
  font-size:${defaultTheme.fontSize.xxxl}
  `};

  ${respondTo.mobile`
  font-size:${defaultTheme.fontSize.xxl}
  `};
`;

const SectionTitle = styled(StyledTitle)`
  font-size: ${defaultTheme.fontSize.xxxxxxl};
  margin-top: ${defaultTheme.spacing.s};
  letter-spacing: ${defaultTheme.spacing.xxs};
  text-transform: uppercase;
  font-family: "Raleway";

  ${respondTo.tablet`
  margin-top: ${defaultTheme.spacing.m};
  font-size:${defaultTheme.fontSize.xxxxxl};
  `};
  ${respondTo.tabletS`
  font-size:${defaultTheme.fontSize.xxxxl};
  `};

  @media (max-width: 768px) {
    font-size: ${defaultTheme.fontSize.xxxxl};
  }

  @media (min-width: 570px) and (max-width: 600px) {
    font-size: ${defaultTheme.fontSize.testimonialsTitleMobile};
    letter-spacing: ${defaultTheme.spacing.xxs};
    margin-top: 5rem;
  }

  @media (max-width: 569px) {
    font-size: ${defaultTheme.fontSize.testimonialsTitleMobileMedium};
  }

  ${respondTo.mobileM`
  font-size:${defaultTheme.fontSize.testimonialsTitleMobileMedium};
  letter-spacing: ${defaultTheme.spacing.base};
  margin-top: 10rem;
  `};

  @media (max-width: 430px) {
    font-size: 1.8rem;
    letter-spacing: ${defaultTheme.spacing.base};
    margin-top: 3rem;
  }
  ${respondTo.mobile326`
    font-size: 1.4rem;
    letter-spacing: 6px;
    margin-top: 4rem;

  `};
  ${respondTo.mobileXS`
    font-size: 1.4rem;
    letter-spacing: 6px;
    margin-top: 3rem;
    `};
`;

export const DecoratedSectionTitle = styled(SectionTitle)`
  letter-spacing: ${defaultTheme.spacing.xs};

  &::first-letter {
    color: ${defaultTheme.colors.geeksGreen};
  }

  &::after {
    content: "+";
    color: ${defaultTheme.colors.geeksGreen};
  }
`;

const HeaderTitle = styled(StyledTitle)`
  font-family: MajorMonoDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 105px;
  line-height: 110px;
  text-align: left;
  text-transform: lowercase;

  ${respondTo.desktopMacP` 
  font-size: 60px;
  line-height: 70px;
`};
  ${respondTo.desktopL` 
  font-size: 50px;
  line-height: 55px;
`};
  ${respondTo.desktop` 
  font-size: 45px;
  line-height: 50px;
`};
  ${respondTo.desktop1200` 
font-size: 40px;
line-height: 45px;
`};
  ${respondTo.tabletL`
  font-size: 50px;
  line-height: 55px;
  `};
  ${respondTo.tablet`
  font-size: 40px;
  line-height: 45px;
  `};
  ${respondTo.tabletS`
  font-size: 36px;
  line-height: 40px;
  `};
  ${respondTo.mobileM` 
  font-size: 30px;
  line-height: 35px;
`};
  ${respondTo.mobile326` 
font-size: 25px;
line-height: 30px;
`};
`;

interface TitleProps extends TitleProperties {
  size: "small" | "medium" | "large" | "huge" | "section" | "header";
  children?: any;
}

export const Title = (props: TitleProps) => {
  switch (props.size) {
    case "small":
      return <SmallTitle {...props}>{props.children}</SmallTitle>;

    case "medium":
      return <MediumTitle {...props}>{props.children}</MediumTitle>;

    case "large":
      return <LargeTitle {...props}>{props.children}</LargeTitle>;

    case "huge":
      return <VeryLargeTitle {...props}>{props.children}</VeryLargeTitle>;

    case "section":
      return <SectionTitle {...props}>{props.children}</SectionTitle>;

    case "header":
      return <HeaderTitle {...props}>{props.children}</HeaderTitle>;
  }
};
