import * as React from "react";
import styled from "styled-components";
import { useIntl, changeLocale } from "gatsby-plugin-react-intl";

import { Align } from "./Align";
import { Text } from "./Text";
import { defaultTheme } from "../../styles/theme";

interface SwitchKnobProps {
  checked: boolean;
}

const SwitchContainer = styled.div`
  width: 74px;
  height: 39px;
  position: relative;
  border-radius: ${defaultTheme.borderRadius.large};
  border: 1px solid ${defaultTheme.colors.lightWhite};
  background-color: ${defaultTheme.colors.brandBlack};
  cursor: pointer;
  margin: 5px;
`;

const Knob = styled.div<SwitchKnobProps>`
  width: 40px;
  height: 40px;
  position: absolute;
  top: -1px;
  left: ${({ checked }) => (checked ? "auto" : "-2px")};
  right: ${({ checked }) => (checked ? "-1px" : "auto")};
  border-radius: ${defaultTheme.borderRadius.round};
  background-color: ${defaultTheme.colors.lightWhite};
  box-shadow: ${defaultTheme.shadow.normal};
`;

const LanguageContainer = styled.div`
  width: 30px;
  height: 30px;
  margin: 3px;
  z-index: 2;
`;

export const Switch = () => {
  const intl = useIntl();

  const [checked, setChecked] = React.useState(intl.locale === "pl");

  const switchHandler = () => {
    if (intl.locale === "pl") {
      changeLocale("en");
    } else {
      changeLocale("pl");
    }
  };

  const { lightWhite, brandBlack } = defaultTheme.colors;

  return (
    <SwitchContainer onClick={switchHandler}>
      <Align direction="row" align="center" justify="center">
        <LanguageContainer>
          <Align direction="row" align="center" justify="center">
            <Text size="medium" align="center" color={checked ? lightWhite : brandBlack} noSelect fontWeight={350}>
              EN
            </Text>
          </Align>
        </LanguageContainer>
        <LanguageContainer>
          <Align direction="row" align="center" justify="center">
            <Text size="medium" align="center" color={checked ? brandBlack : lightWhite} noSelect fontWeight={350}>
              PL
            </Text>
          </Align>
        </LanguageContainer>
      </Align>
      <Align direction="row" align="center" justify="flex-start">
        <Knob checked={checked} />
      </Align>
    </SwitchContainer>
  );
};
