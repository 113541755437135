import styled from "styled-components";
import * as React from "react";

interface SectionProps {
  fixed?: boolean;
  id?: string;
  background?: string;
  children: React.ReactNode;
  contentWidth?: string;
  fullWidth?: boolean;
}

type ContentWidthProps = {
  contentWidth?: string;
};

const SectionContainer = styled.div<SectionProps>`
  display: flex;
  justify-content: center;
  width: 100vw;
  position: ${({ fixed }) => (fixed ? "fixed" : "static")};
  background: ${({ background }) => (background ? background : "transparent")};
  z-index: ${({ fixed }) => fixed && "10"};
  overflow-x: hidden;
`;

const SectionContent = styled.div<SectionProps>`
  width: ${({ theme, contentWidth, fullWidth }) =>
    fullWidth ? "100vw" : contentWidth ? contentWidth : theme.applicationWidth};
  max-width: 100%;
`;

export const Section = ({ children, background, fixed, id, fullWidth, contentWidth }: SectionProps) => (
  <SectionContainer background={background} fixed={fixed} id={id}>
    <SectionContent fullWidth={fullWidth} contentWidth={contentWidth}>
      {children}
    </SectionContent>
  </SectionContainer>
);
