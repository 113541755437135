import * as React from "react";
import styled from "styled-components";

import { Align } from "../UI/Align";

import { defaultTheme } from "../../styles/theme";
import { respondTo } from "../../styles/respondTo";

interface ButtonProps {
  title?: string;
  img?: string;
  color?: string;
  onPress: () => void;
}

type ButtonContainerProps = {
  title?: string;
  img?: string;
  color?: string;
};

const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: ${props => (props.color ? props.color : `${defaultTheme.colors.white}`)};
  font-family: Raleway;
  white-space: nowrap;
  letter-spacing: 0.8vh;
  outline: none;
  cursor: pointer;
  padding: ${defaultTheme.spacing.xs} ${defaultTheme.spacing.xxl};
  font-size: ${defaultTheme.fontSize.xl};
  position: relative;
  top: 8px;

  ${respondTo.tablet`
    font-size:${defaultTheme.fontSize.l};
    text-transform: uppercase;
    padding: 13px 142px;
`};
`;

const StyledImg = styled.img`
  margin: ${defaultTheme.spacing.base};
`;

export const Button = ({ color, title, img, onPress }: ButtonProps) => {
  return (
    <ButtonContainer color={color} img={img} title={title} onClick={onPress}>
      <Align direction="row" align="center" justify="center">
        {img && <StyledImg src={img} />}
        {title}
      </Align>
    </ButtonContainer>
  );
};
