import * as React from "react";
import styled from "styled-components";
import { defaultTheme } from "../../styles/theme";
import { Text } from "./Text";

interface InputProps {
  value: number | string;
  name?: string;
  id: string;
  label: string;
  spacing?: string;
}

interface InputContainerProps {
  spacing?: string;
}

const InputContainer = styled.div<InputContainerProps>`
  margin: ${({ spacing }) => spacing || "5px"} 5px;
  cursor: pointer;

  position: relative;
  label {
    padding-left: 25px;
    cursor: pointer;
  }
  &::before {
    content: "";
    border-radius: 100%;
    border: 2px solid ${defaultTheme.colors.accent};
    background: ${defaultTheme.colors.lightGrey};
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  background: ${defaultTheme.colors.green};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 4px;
  left: 4px;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: 16px;
    position: absolute;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid ${defaultTheme.colors.accent};
    border-radius: 100%;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: 12px;
      height: 12px;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

export const RadioInput = ({ value, name, id, label, spacing }: InputProps) => {
  return (
    <InputContainer spacing={spacing}>
      <label>
        <Text size="medium" color={defaultTheme.colors.lightGrey}>
          {label}
        </Text>
        <StyledInput type="radio" name={name} id={id} value={value} />
        <Fill />
      </label>
    </InputContainer>
  );
};
