import { DefaultTheme } from "styled-components";
import { colors } from "./colors";

const defaultTheme: DefaultTheme = {
  borderRadius: {
    small: "10px",
    medium: "20px",
    large: "40px",
    round: "50%"
  },
  applicationWidth: "1920px",
  shadow: {
    normal: "1px 1px 2px rgba(63, 61, 86, 0.25)",
    wide: "0px 4px 12px 2px rgba(0, 0, 0, 0.4)"
  },
  spacing: {
    small: "4px",
    base: "8px",
    xxs: "12px",
    xs: "16px",
    s: "24px",
    m: "32px",
    l: "40px",
    xl: "48px",
    xxl: "56px",
    xxxl: "64px",
    xxxxl: "72px",
    xxxxxl: "80px"
  },
  fontSize: {
    xs: "12px",
    s: "14px",
    m: "16px",
    font18: "18px",
    l: "20px",
    font22: "22px",
    xl: "24px",
    font26: "26px",
    xxl: "32px",
    xxxl: "38px",
    testimonialsTitleMobile: "48px",
    testimonialsTitleMobileMedium: "38px",
    testimonialsTitleMobileSmall: "30px",
    xxxxl: "50px",
    xxxxxl: "60px",
    xxxxxxl: "72px",
    section: "104px"
  },
  colors: {
    brand: colors.brand,
    white: colors.white,
    black: colors.black,
    primary: colors.primary,
    lightGrey: colors.lightGrey,
    lightGreyBackground: colors.lightGreyBackground,
    grey: colors.grey,
    darkGrey: colors.darkGrey,
    mediumGrey: colors.mediumGrey,
    accent: colors.accent,
    green: colors.green,
    divider: colors.divider,
    darkPurple: colors.darkPurple,
    logoGreen: colors.logoGreen,
    headerDark: colors.headerDark,
    red: colors.red,
    brandBlack: colors.brandBlack,
    lightWhite: colors.lightWhite,
    geeksGreen: colors.geeksGreen,
    textLight: colors.textLight
  },
  transition: {
    easeIn: "all 0.3s ease-in;",
    linear: "all .3s linear"
  }
};
export { defaultTheme };
